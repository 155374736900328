import React, { useEffect } from 'react';
import './Timeline1.css';

import regIcon from "../../images/registration-form.webp";
import submissionIcon from "../../images/submission.webp";
import workshop1Icon from "../../images/onlineWorkshopSeries1.webp";
import workshop2Icon from "../../images/onlineWorkshopSeries2.webp";
import trophyIcon from "../../images/trophy.webp";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const timelineData = [
  {
    key: "1",
    icon: regIcon,
    title: "Registration",
    date: "1st of July 2024",
    description: "Upon the opening of registrations, individuals interested in participating can fill their team details through the provided registration form and access the sample proposal by clicking the “Proposal Template” button which will be displayed above.",
    deadline: "Deadline: 1st of July 2024",
  },
  {
    key: "2",
    icon: submissionIcon,
    title: "Proposal Submissions Open",
    date: "14th of July 2024",
    description: "All registered applicants are required to prepare their proposals in accordance with the provided sample structure and submit them through this official website.",
    deadline: "Deadline: 14th of July 2024",
  },
  {
    key: "3",
    icon: workshop2Icon,
    title: "ideaX (Semi Finals of hackX-Initial Ideathon)",
    date: "17th of August 2024",
    description: "Following the initial screening process, 30 teams showcasing the most innovative ideas will be selected. Each team will have 7 minutes to pitch their ideas, followed by a 3-minute Q&A session. The top 12 teams will then advance to compete at the Grand Finals of hackX 9.0.",
    deadline: "Date: 17th of August 2024",
    venue: "Venue: Department of Industrial Management, University of Kelaniya",
  },
  {
    key: "4",
    icon: workshop1Icon,
    title: "designX",
    date: "24th of August 2024 - 14th of September 2024",
    description: "A workshop series of four skill-building sessions, tailored for finalists at the Grand Finals of hackX 9.0.",
    deadline: "Date: 24th of August 2024 - 14th of September 2024",
  },
  {
    key: "5",
    icon: trophyIcon,
    title: "hackX 9.0: Grand Finals",
    date: "29th of September 2024",
    description: "This event represents the ultimate stage where contestants will present their further refined innovative ideas. Fifteen teams will compete: twelve teams selected from ideaX and three teams from ideasprint.",
    deadline: "Date: 29th of September 2024",
  }
];

const Timeline1 = ({ pageRef }) => {
  return (
    <section ref={pageRef}>
      <div className="container py-5">
        <div className="main-timeline">
          {timelineData.map((element, index) => (
            <TimelineCard
              key={element.key}
              element={element}
              isLeft={index % 2 === 0}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const TimelineCard = ({ element, isLeft }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        x: 0,
        transition: { duration: 0.5 }
      });
    } else {
      controls.start({
        opacity: 0,
        x: isLeft ? -50 : 50,
      });
    }
  }, [controls, inView, isLeft]);

  return (
    <div
      ref={ref}
      className={`timeline ${isLeft ? 'left' : 'right'}`}
      style={{ position: 'relative' }}
    >
      <motion.div
        initial={{ opacity: 0, x: isLeft ? -50 : 50 }}
        animate={controls}
        className="card"
        style={{ borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(1.6px)", WebkitBackdropFilter: "blur(1.6px)", overflow: "hidden" }}
      >
        <div className="card-body p-4">
          <div className="timeline-content">
            <img src={element.icon} alt={`${element.title} Icon`} className="timeline-icon" />
            <h3 style={{ color: "white", fontWeight: "bold" }}>{element.title}</h3>
          </div>
          <p style={{ color: "#1e99f1", fontWeight: "bold" }}>{element.deadline}</p>
          {element.venue && <p style={{ color: "#1e99f1", fontWeight: "bold", marginTop: "-10px" }}>{element.venue}</p>}
          <p style={{ color: "white" }} className="mb-0">
            {element.description}
          </p>
        </div>
      </motion.div>
      <div className="timeline-circle" style={{ 
        width: '25px', 
        height: '25px', 
        backgroundColor: '#1e99f1', 
        border: '5px solid #1e99f1', 
        borderRadius: '50%', 
        position: 'absolute', 
        top: '15px', 
        zIndex: '1', 
        left: (window.innerWidth <= 600 && isLeft) ? '18px' : (isLeft ? 'auto' : '-18000px'), 
        right: (window.innerWidth <= 600 && !isLeft) ? '18000px' : (isLeft ? '-13px' : 'auto')
      }}></div>
    </div>
  );
};

export default Timeline1;
